import React from 'react';
import Footer from '../components/Footer';
import Highlights from '../components/Highlights';

function highlights() {
    return (
        <div>
            <Highlights/>
            <Footer/>
        </div>
    );
}

export default highlights;